/* titles => font-family: 'Yeseva One', cursive; */
/* paragraph => font-family: 'Josefin Sans', sans-serif; */
body {
    margin: 0;
    
}



                                     /* Header Styling */

header {
    height: 550px;
    width: 100%;
    background-image: url("./assets/portfolio-background-header.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}
#header-elements {
    width: 60%;
    max-width: 1360px;
    min-width: 770px;
    position: relative;
    margin: auto;
}
#header-title {
    margin: 0;
    position: absolute;
    left: 0;
    top: 135px;
    font-size: 35px;
    font-family: 'Josefin Sans', sans-serif;
}

#header-nav {
    width: 40%;
    position: absolute;
    top: 450px;
    margin-left: 100px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    text-decoration: none;
}

.nav-link{
    font-family: 'Josefin Sans', sans-serif; 
    color: white;
    text-decoration: none; 
    font-size: 22px;
    font-weight: 600;
    transition: all .2s ease-in-out;

}

.nav-link:hover{
    transform: scale(1.3);
}

#header-job-title {
    position: absolute;
    left: 0;
    top: 200px;
    font-size: 35px;
    font-weight: 700;
    font-family: 'Josefin Sans', sans-serif;
}

#header-job-title h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Yeseva One', cursive;
}
#header-job-title h5{
    margin-top: 0;
    margin-bottom: 0;
}


#filter {
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 46, 0.342);
}

#social-media-icons-header{
    position: absolute;
    right: 0;
    top: 100px;
}

.social-media-icons-header img {
    margin: 5px;
}

.my-photo {
    border-radius: 50%;
    border: 5px solid white;
    width: 300px;
    position: absolute;
    right: 10px;
    top: 300px;
    z-index: 5;
}
.summary-red-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #FF484C;
    background-color: #FF484C;
    margin-right: 15px;
    margin-left: -1px;
    border-radius: 50%;
}
.summary-red-line {
    display: inline-block;
    width: 80px;
    height: 9px;
    border-top: 4px solid #FF484C;
}

.summary-section {
    background-color: #f4f4f4;
    font-size: 25px;
    height: 350px;
    margin-top: 7px;
    position: relative;
}

p {
    width: 60%;
    max-width: 1360px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Josefin Sans', sans-serif;

}


/* Side Nav */

.project-page {
    display: grid;
    }
    

    /* Project Page */

.projects-container{
    padding-left: 250px;
    padding-top: 50px;
    width: 70vw;
    height: 100vh;
    align-items: center;
    overflow-y: scroll;
   
}

.nav-bar{
    float: left;
    padding: 20px;
    background-color: whitesmoke;
    width: 25vw;
    height: 100vh;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
}

.nav-top {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
    margin: 0;
    padding-top: 50px;
    position: relative;
    text-align: center;
    color: #FF484C;
}

.nav-top p {
    padding-top: 100px;
    padding-bottom: 0px;
    margin-bottom: 0;
}

.nav-icons {
    text-align: center;
}
.nav-icons a {
    color: #FF484C;
	background: rgba(255,255,255,0);
    font-size: 35px;
    margin: 10px;

}

.nav-icons a:hover{
    color: tomato;
}

.nav-links li{
    margin-top: 50px;
    list-style-type: none;
    text-align: center;
}


.link{
    font-family: 'Josefin Sans', sans-serif;
    color: #FF484C;
    text-decoration: none; 
    font-size: 25px;
    font-weight: 1000;
    transition: all .2s ease-in-out;
}

.link:hover {
    color: tomato;
}


/* Skill page */

.skill-page {
    display: grid;
}

.skill-container{
    float: right;
    padding: 20px;
    width: 70vw;
    height: 100vh;
    

}

.title-line{
    display: inline-block;
    width: 90%;
    height: 9px;
    border-top: 4px solid #FF484C;
    margin-bottom: 50px;
}

.languages{
    color: #FF484C;
    font-family: 'Yeseva One', cursive;
    padding-top: 70px;
}

.language-icons {
    display: flex;
    justify-content: center;
}

.icon-box {  
  width: 50px;
  height: 50px;
  margin: 10px
}

.toolbox{
    position: relative; 
    text-align: center;
    padding: 0; 
}
.toolbox text {
    color: #FF484C;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: 700px;
    line-height: 50px;
}

/* Tablet */
@media (min-width: 667px) and (max-width: 1024px){
.my-photo {
    top: 550px;
    right: 50px;
}

header {
    height: 850px;
}

#header-elements {
    min-width: 667px;
}

#social-media-icons-header {
    top: 130px;
    right: 40px;
}

#header-job-title {
    top: 340px;
    left: 70px;
}

.summary-section {
    height: 550px;
}

}


/* Mobile */
@media (max-width: 666px) {
    header {
        height: 600px;
    }

    #header-nav{
        left: 30px;
        top: 400px;
    }

    .nav-link{
        margin: 10px
    }
    #header-elements {
        width: 100%;
        min-width: 320px;
        max-width: 500px;
        margin: 0 auto;
    }


    #social-media-icons-header {
        top: 130px;
        right: 30px;
    }

    #social-media-icons-header img {
        width: 45px;
    }

    #header-job-title {
        top: 200px;
        left: 30px;
        font-size: 25px;
    }

    .my-photo {
        top: 460px;
        right: 85px;
        width: 150px;
        border: 3px solid white;
    }

    .summary-section {
        height: 450px;
        font-size: 17px;
    }

    .summary-section p {
        width: 80%;
    }

    .summary-red-line {
        width: 30px;
        height: 4px;
        border-top: 2px solid #FF484C;
    }
    
    .summary-red-dot {
        width: 6px;
        height: 6px;
    }
}




/* Project Card */


.img-wrapper{
	width: 600px;
	height: 400px;
	position: relative;
	overflow: hidden;
    box-shadow: 6px 5px 4px 1px #8f8f8f;
    margin-bottom: 60px;
}

.img-wrapper:before{
	content: '';
	position: absolute;
	top: 0;
	left: 180%;
	height: 100%;
	width: 100%;
	background: rgba(255,255,255,.3);
	z-index: 1;
	transform: skew(45deg);
	transition: .5s;
}

.img-wrapper:hover:before
{
	left: -180%;
}

.img-wrapper img
{
	height: 400px;
	width: 600px;
	transition: 2s;
}

.img-wrapper:hover img
{
	filter: grayscale(100%);
	transform: scale(1.1);
}

.img-wrapper h2
{
	background: #ff484be3;
	font-family: Poppins;
	color: #fff;
	text-align: center;
    font-family: 'Yeseva One', cursive;
	margin: 0;
	padding: 10px, 0;
	position: absolute;
	bottom: 0;
	width: 100%;
    height: 30%;
	transform: perspective(400px) rotateY(95deg);
	transform-origin: right;
	transition: 1s;
  
}

.img-wrapper p {
    font-size: small;
    padding-top: 20px;
    font-family: 'Josefin Sans', sans-serif;
}

.img-wrapper:hover h2
{
	transform: perspective(400px) rotateY(0deg);
}

.img-wrapper ul
{
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	background: rgba(255,255,255,0);
}

.img-wrapper ul li
{
	background: rgb(71, 71, 71);
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	transform: perspective(800px) rotateY(90deg);
	transition: .5s;
	transform-origin: left;
}

.img-wrapper:hover ul li
{
	transform: perspective(800px) rotateY(0deg);
}

.img-wrapper:hover ul li:nth-child(1)
{
	transition-delay: .2s;
}

.img-wrapper:hover ul li:nth-child(2)
{
	transition-delay: .6s;
}

.img-wrapper:hover ul li:nth-child(3)
{
	transition-delay: .8s;
}


.img-wrapper ul li a
{
	color: #FF484C;
	background: rgba(53, 34, 34, 0);
}

.img-wrapper ul li i
{
	color: tomato;
    font-size: 25px;
	background: rgba(255,255,255,0);
}

.img-wrapper ul li i:hover
{
	color: #fff;
	background: rgba(255,255,255,0);
}


.contact{
    width: 75vw;
    height: 100vh;
    margin-left: auto;
}
.form-title{
    font-family: 'Yeseva One', cursive;
    color: #ff484be3;

}
.form-container{
    text-align: center;
    background-color: whitesmoke;
    display: flex;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 10px;
    justify-content: center;
    flex-direction: column;
    width: 600px;
    height: 100vh;
}

.form-input{
    border: none;
    outline: none;
    margin-top: 30px;
    text-align: center;
    background-color: rgba(201, 201, 201, 0.562);
    border-radius: 25px;
    height: 50px;
    width: 80%;
    background: #e6e6e6;
    font-family: 'Yeseva One', cursive;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    overflow: visible;
}

#message{
    height: 200px;
}

.form-button{
    margin-top: 30px;
    border: none;
    font-family: 'Yeseva One', cursive;
    font-size: 15px;
    color:   #ff484be3;
    background-color: whitesmoke;
}

.form-button:hover{
    font-size: 20px;
}


.footer{
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    color: #FF484C;
    font-size: 15px;
    background-color: transparent;
    height: 30px;
    margin-top: 0px;
    padding-top: 10px;
    bottom: 0;

}

.footer h5{
    margin-top: 5px;
}

.nav-footer{
    margin-top: 100px;
}